<!-- =========================================================================================
  File Name: projects.vue
  Description: All Projects list
  ----------------------------------------------------------------------------------------
  Item Name: KOZO Admin - VueJS Dashboard 
  Author:  
  Author URL:  
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full">
        <router-link to="/apps/projects">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>
      <!-- DEPARTMENT FORMS -->
      <div class="vx-col w-full mb-base">
        <department-form v-if="checkPermission(['department','POST'])"></department-form>
      </div>

      <div class="vx-col w-full">
        <vs-tabs>
          <vs-tab label="Department List" icon-pack="feather" icon="icon-box">
            <div class="tab-text">
              <!-- DEPARTMENT LIST -->
              <div>
                <department-list v-if="checkPermission(['department','GET'])"></department-list>
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Archive List" icon-pack="feather" icon="icon-box">
            <div>
              <department-list listType="archive"></department-list>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import departmentForm from "@/views/apps/department/departmentForm.vue";
import departmentList from "@/views/apps/department/departmentList.vue";

export default {
  data() {
    return {
      isActive: false,
      analyticsData: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ]
    };
  },
  methods: {
    myFilter: function() {
      this.isActive = !this.isActive;
    }
  },
  components: {
    departmentForm,
    departmentList
  }
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
.con-vs-tabs .con-ul-tabs,
.vs-tabs--ul {
  background-color: #fff !important;
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul {
  background-color: #10163a !important;
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul span {
  color: #c2c6dc;
  font-weight: bold;
}
</style>
