<template>
  <div v-if="checkPermission(['department', 'PUT'])">
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>
    <div class="centerx">
      <vs-popup
        :id="'popmodal' + params.data.department_id"
        class="holamundo editModal"
        title="Update Department"
        :active.sync="popupActive"
      >
        <form>
          <FocusLock>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="col-12">
                <div class="vx-row mb-6">
                  <div
                    class="col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Department Name*</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        v-validate="{
                          required: true,
                          regex: /^[A-Za-z0-9 ]+$/,
                        }"
                        name="department name"
                        v-model="form.department_name"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('department name')"
                        >{{ errors.first("department name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <!-- <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Company*</h5>
                  </div>
                  <div class="col-7">                   
                    <div>
                      <multiselect
                        v-model="company_value"
                        track-by="company_id"
                        label="company_name"
                        @input="({company_id})=> this.form.company_id = company_id"
                        :options="companies"
                        name="Company Name"
                        :searchable="false"
                        :deselectLabel="''"
                        :select-label="''"
                        :allow-empty="false"
                        deselect-label="Can't remove this value"
                      >
                        <template
                          slot="singleLabel"
                          slot-scope="{ option }"
                        >{{ option.company_name }}</template>
                      </multiselect>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Company Name')"
                      >{{ errors.first('Company Name') }}</span>
                    </div> 
                  </div>
                </div>-->

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-left md:text-right">
                    <h5 class="mb-0">Description</h5>
                  </div>
                  <div class="col-7">
                    <vs-textarea
                      v-model="form.description"
                      class="mb-0"
                      rows="4"
                    />
                  </div>
                </div>
                <!-- <div class="vx-row mb-6">
                <div class="col-md-4 text-right">
                  <h5 class="mb-0">Status*</h5>
                </div>
                <div class="col-7">
                  <b-form-checkbox
                    value="1"
                    unchecked-value="2"
                    v-model="form.department_active"
                    switch
                  ></b-form-checkbox>
                </div>
                </div>-->
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <!-- :disabled="submitStatus" -->
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Save</vs-button
                >

                <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import departmentService from "@/services/departmentService.js";
import CompanyService from "@/services/companyService.js";
export default Vue.extend({
  data() {
    return {
      switch1: true,
      submitStatus: true,
      form: {
        department_name: "",
        description: "",
        company_id: "",
        department_active: "1",
      },
      new_company_id: null,
      companies: [],
      company_value: [],
      department_value: [],
      popupActive: false,
      params: null,
      value: null,
      count: 0,
    };
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (newVal.department_name) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.form = this.params.data;
    this.department_id = this.params.value;
    this.department_name = this.params.value;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodal" + this.params.data.department_id
    );
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    showModal() {
      // console.log(this.params.data.department_active, "fsdfsd");
      // console.log("afsadf");
      this.popupActive = true;
      // this.getCompanyList();
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        this.$vs.loading();
        if (result) {
          let id = this.params.data.department_id;
          let payload = {
            department_name: this.form.department_name,
            company_id: this.form.company_id,
            description: this.form.description,
            department_active: this.form.department_active,
          };
          departmentService
            .editDepartment(payload, id)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              this.popupActive = false;

              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                setTimeout(() => {
                  eventBus.$emit("refreshTabledepa", data);
                }, 1);
                this.clearForm();
              } else {
                this.$vs.loading.close();
                console.log("data :", data);
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },
    // getCompanyList: function() {
    //   // console.log("RolesService :", RolesService);
    //   CompanyService.getAllCompanies()
    //     .then(response => {
    //       const { data } = response;
    //       if (data.err || data.Status == false) {
    //       } else {
    //         this.companies = data.data;
    //         this.companies.map(x => {
    //           if (this.params.data.company_id == x.company_id) {
    //             this.company_value = x;
    //           }
    //         });
    //       }
    //     })
    //     .catch(error => {
    //       console.log("error :", error);
    //     });
    // },
    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    },
  },

  mounted() {
    // this.getCompanyList();
  },
});

// import Vue from "vue";

// export default Vue.extend({
//   template: ` {{params.value}}
//          <label class="switch">
//           <input @click="onChangeCheckbox" type="checkbox">
//           <span class="slider round"></span>
//     </label> `,
//   data: function () {
//     return {};
//   },
//   beforeMount() {},
//   mounted() {},
//   methods: {

//   }
// });
</script>
<style></style>
